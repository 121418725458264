<template>
<article class="columns">
  <section class="column">
    <div class="modal" style="width: auto" v-bind:class="{ 'is-active': isShowDialog }">
    <div class="modal-background"></div>
    <div class="modal-card scrollable" style="width: auto">
        <form action="" class="box" @submit.prevent="submitForm">
        <header class="modal-card-head">
        <p class="modal-card-title">Vendor Details</p>
        <button class="delete is-danger" type="button" @click="closeDialog">Cancel</button>
        </header>
        <section class="modal-card-body">

            <div class="columns">
                <div class="column">
                    <b-field label="Name *" label-for="name">
                        <b-input custom-class="input is-danger" name="name" id="name" type="text"  required icon-pack="fas" icon="building" v-model="vendor.name" validation-message="Please enter business name"></b-input>
                    </b-field>
                </div>
                <div class="column">
                    <b-field label="Office Phone *" label-for="officePhone">
                        <b-input 
                            custom-class="input is-danger"
                            name="officeNumber" 
                            id="officeNumber" 
                            required
                            type="tel"  
                            icon-pack="fas" 
                            icon="mobile" 
                            v-model="vendor.officeNumber" 
                            validation-message="Please enter Office Phone Number"
                            ></b-input>
                    </b-field>
                </div>
                <!-- <div class="column">
                    <b-field label="Vendor Description" label-for="fein">
                        <b-input name="fein" id="fein" type="text" icon-pack="fas" icon="mobile" v-model="vendor.fein" validation-message="Please enter any additional information"></b-input>
                    </b-field>
                </div> -->
                <div class="column is-2">
                    <b-field label="Active" label-for="active">
                        <b-checkbox disabled v-model="vendor.active">
                            <!-- {{ vendor.active }} -->
                        </b-checkbox>
                    </b-field>
                </div>
            </div>

            <div class="columns">
                <div class="column">
                    <b-field label="Address" label-for="addressLine1">
                        <b-input name="addressLine1" id="addressLine1" type="text" icon-pack="fas" icon="building" v-model="vendor.addressLine1" validation-message="Please enter Street adddress"></b-input>
                    </b-field>
                </div>
                <div class="column">
                    <b-field label="City" label-for="city">
                        <b-input name="city" id="city" type="text" icon-pack="fas" icon="building" v-model="vendor.city" validation-message="Please enter City"></b-input>
                    </b-field>
                </div>
                <div class="column">
                    <b-field label="State" label-for="state">
                        <b-input  name="state" id="state" type="text" icon-pack="fas" icon="building" v-model="vendor.state" validation-message="Please enter State"></b-input>
                    </b-field>
                </div>
                <div class="column">
                    <b-field label="Zip Code" label-for="zipCode">
                        <b-input name="zipCode" id="zipCode" type="text" icon-pack="fas" icon="building" v-model="vendor.zipCode" validation-message="Please enter Zip Code"></b-input>
                    </b-field>
                </div>            
            </div>

            <div class="columns">
                <div class="column">
                    <b-field label="Email" label-for="email">
                        <b-input name="companyEmail" id="companyEmail" type="email" icon-pack="fas" icon="envelope" v-model="vendor.companyEmail" validation-message="Please enter a vaild email address"></b-input>
                    </b-field>
                </div>
                <div class="column">
                    <b-field label="Website" label-for="website">
                        <b-input name="website" id="website" type="text" icon-pack="fas" icon="firefox" v-model="vendor.website" validation-message="Please enter Web Address"></b-input>
                    </b-field>
                </div>
            </div>

            <div class="columns">
                <div class="column">
                    <b-field label="Contact First Name" label-for="firstName">
                        <b-input name="firstName" id="firstName" type="text" icon-pack="fas" icon="user-tie" v-model="vendor.firstName" validation-message="Please enter Contact First Name"></b-input>
                    </b-field>
                </div>
                <div class="column">
                    <b-field label="Contact Last Name" label-for="lastName">
                        <b-input name="lastName" id="lastName" type="text" icon-pack="fas" icon="user-tie" v-model="vendor.lastName" validation-message="Please enter Contact Last Name"></b-input>
                    </b-field>
                </div>
                <div class="column">
                    <b-field label="Contact Cell Phone" label-for="cellNumber">
                        <b-input name="cellNumber" id="cellNumber" type="tel" icon-pack="fas" icon="mobile" v-model="vendor.cellNumber" validation-message="Please enter Contact Cell Phone Number"></b-input>
                    </b-field>
                </div>
            </div>
        </section>

        <footer class="modal-card-foot">
        <button class="button is-success" type="button" @click="submitForm" :disabled='isDisabled'>Save</button>
        <button class="button" type="button" @click="closeDialog">Cancel</button>
        <span class='is-danger'></span>
        </footer>
    </form>

    </div>
    </div>
  </section>
</article>
</template>


<script>

export default {
    props: {
         'canCancel': String, 
         'isShowDialog': Boolean,
         'vendor': {},
         'storeId': Number,
         'id': Number,
         'action': String
    },

    data() {
        return {
            'email': '',
            'role': '',
        }
    },

    methods: {

        closeDialog() {
            this.$emit('hide-dialog')
            this.email = ""
            this.role = ""
        },

        submitForm() {

            this.$emit('save-record')
            this.closeDialog()

        },

    },

    computed: {

        isDisabled() {
            if (this.vendor.name && 
                this.vendor.officeNumber
                // this.vendor.addressLine1 && 
                // this.vendor.city && 
                // this.vendor.state &&
                // this.vendor.zipCode
                ) {
                    return false
                } else {
                    return true
                }

        }

    },

}
</script>

<style scoped>

/* .scrollable {
    max-height: 200vw;
    overflow: auto; 
} */


</style>