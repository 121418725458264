<template>
    <div class="panel">
        <div class="panel-heading has-background-grey-lighter has-text-weight-bold">
            <div class="columns is-mobile">
                <div class="column is-size-6 has-text-centered">VENDORS</div>
                <div class="level-right has-text-centered">
                    <div class="button is-primary has-text-white is-small" @click="doAction('add')">
                        <i class="fas fa-file-invoice-dollar"></i>&nbsp;&nbsp;New</div>&nbsp;&nbsp;&nbsp;&nbsp;
                </div> 

            </div>
        </div>

        <hr>
        <b-table class="control has-text-weight-semibold"
            :data='records'
            :paginated="isPaginated"
            :per-page="perPage"
            paginationPosition="both"
            sortIcon="arrow-up"
            sortIconSize="is-small"
            default-sort="name"
            defaultSortDirection='asc'
            >

            <template>

                <b-table-column class="is-2 has-text-right" field="amount" label="Edit" v-slot="props" sortable sorticon>
                    <b-button class="is-primary is-small" outlined rounded  v-on:click="doAction('edit',props.row)"><i class="far fa-edit"></i></b-button>
                </b-table-column> 

                <b-table-column field="active" label="Active" v-slot="props">
                    <i class="fas fa-check-circle  has-text-success" v-if="props.row.active"></i>
                    <i class="fas fa-dot-circle  has-text-danger" v-else></i>
                </b-table-column>

                <b-table-column field="name" label="Name" v-slot="props" searchable sortable sorticon>
                    {{ props.row.name }}
                </b-table-column>

                <b-table-column field="address" label="Address" v-slot="props" sortable sorticon>
                   {{ props.row.city }} {{ props.row.state }} {{ props.row.zipCode }}
                </b-table-column>

                <b-table-column field="officeNumber" label="Phone Number" v-slot="props" sortable sorticon>
                    {{ props.row.officeNumber | formatUSAPhoneNumber }}
                </b-table-column>

                <b-table-column field="companyEmail" label="Email" v-slot="props" sortable sorticon>
                    {{ props.row.companyEmail }}
                </b-table-column>
<!-- 
                <b-table-column class="" v-slot="props">
                    <b-tooltip
                        class=" is-primary is-small"
                        position="is-left"
                        :label="getEditToolTip(props.row.name)"
                        :delay="1000">
                        <i class="button" outlined v-on:click="doAction('edit',props.row)">
                            <i class="fas fa-edit"></i>
                        </i>
                    </b-tooltip>                    
                </b-table-column> -->

            </template>
            <template slot="empty">
                <data-loading 
                    :isLoading="isLoading" 
                    :isFullPage="isFullPage"> 
                </data-loading>
            </template>
            <template slot="top-left">
                <a class="button is-primary" v-on:click="doAction('add')">
                    <i class="fas fa-money-check"></i><span class="btn-label-text">New</span>
                </a>
            </template>

            <template slot="bottom-left">
                <a class="button is-primary" v-on:click="doAction('add')">
                   <i class="fas fa-money-check"></i><span class="btn-label-text">New</span>
                </a>
            </template>

        </b-table>
        <div ref="container"></div>
        <vendor-form
            :storeId="storeId"
            :vendor="vendorTemp"
            :action="action"
            :isShowDialog="isShowDialog"  
            @hide-dialog="toggleDialog"
            @save-record="saveRecord"
            @refresh-data="fetchRecords">
        </vendor-form>

    </div>
</template>

<script>
import UserStore from "../../../../store/UserStore"

import DataLoading from '../../../../components/app/DataLoading'

import VendorForm from './VendorForm'

import axios from 'axios'

export default {

    components: {
        DataLoading,
        VendorForm
    },

    metaInfo: {
        title: 'Store Vendors'
    },

    data() {
        return {
            user: [],
            records: [],
            vendor: {},
            vendorTemp: {},
            isPaginated: true,
            perPage: 10,
            isLoading: true,
            isFullPage: false,
            isShowDialog: false,
            action: '',
            storeId: 0,
            role: '',
            deleteAllowed: true,
            dateRange: [new Date(), new Date()],

        }
    },

    methods: {

        fetchRecords() {
            if (this.user.activeStore) {
                const url = process.env.VUE_APP_API_ROOT_URL + '/stores/' + this.user.activeStore 
                            + '/vendors'

                axios.get(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken
                        },                   
                    })
                    .then( (response) => {
                        if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)
                        this.records = response.data.data
                        this.isLoading = false
                    })
                    .catch( (err) => {
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(err.response.status, err.response.data)
                        this.isLoading = false
                        if (err.response.status === 403) {
                            alert('Session Expired. Please log in again!')
                            UserStore.commit('userLogout')
                        }
                    })
            }
            
        },

        toggleDialog() {
            this.isShowDialog = ! this.isShowDialog
        },

        saveRecord() {
            this.isLoading = true
            if (this.vendorTemp.id > 0) {
                this.updateRecord()
            } else {
                this.addRecord()
            }

        },

        updateRecord() {

            var url = process.env.VUE_APP_API_ROOT_URL + 'vendors'
            axios.put(url, 
                this.vendorTemp,
            {
                headers: {
                    'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                    'Refresh-Token': UserStore.getters.getRefreshToken
                },
            })
            .then( () => {
                this.fetchRecords()
            })
            .catch ( (err) => {
                console.log(err.response.status)
            })
            this.isLoading = false                  
        },

        addRecord() {

            var url = process.env.VUE_APP_API_ROOT_URL + 'stores/' + this.user.activeStore + '/vendors'
            axios.post(url, 
                this.vendorTemp,
            {
                headers: {
                    'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                    'Refresh-Token': UserStore.getters.getRefreshToken
                },
            })
            .then( () => {
                this.fetchRecords()
            })
            .catch ( (err) => {
                console.log(err.response.status)
            })
            this.isLoading = false                  
        },        

        deleteRecord(vendorid) {

            var url = process.env.VUE_APP_API_ROOT_URL + 'vendors/' + vendorid
            axios.delete(url,
            {
                headers: {
                    'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                    'Refresh-Token': UserStore.getters.getRefreshToken
                },
            })
            .then( () => {
                this.fetchRecords()
            })
            .catch ( (err) => {
                console.log(err.response.status)
            })
            this.isLoading = false                  
        },     

        getEditToolTip(delname) {
            return 'Edit ' + delname
        },

        getDeleteToolTip(delname) {
            return 'Delete ' + delname
        },

        doAction(action, vendor) {

            this.action = action
            this.storeId = this.user.activeStore

            switch(this.action) {
                case 'add':
                    this.toggleDialog()
                    this.vendorTemp = {
                        "active": true,
                        "addressLine1": "",
                        "addressLine2": "",
                        "addressLine3": "",
                        "cellNumber": "",
                        "city": "",
                        "companyEmail": "",
                        "country": "",
                        "dun": "",
                        "fein": "",
                        "firstName": "",
                        "lastName": "",
                        "name": "",
                        "officeNumber": "",
                        "state": "",
                        "website": "",
                        "zipCode": ""
                    }
                    break

                case 'edit':
                    this.toggleDialog()
                    this.vendor = vendor
                    this.vendorTemp = Object.assign({}, vendor)
                    
                    break

                case 'delete':
                    console.log("Delete " + vendor.name)
                    this.$buefy.dialog.confirm({
                        title: 'Removing User Authorization',
                        message: 'Are you sure you want to delete <b>' + vendor.name +'</b>? This action cannot be undone.',
                        confirmText: 'Delete',
                        type: 'is-danger',
                        hasIcon: true,
                        onConfirm: () => { 
                            this.deleteRecord(vendor.id)
                            this.$buefy.toast.open('Account deleted!')
                        }
                    }) 
                    break
            }

        },
    },

    computed: {
        // getUser() {
        //     return UserStore.getters.getUser
        // },

        getActiveStore() {
            return UserStore.getters.getActiveStore
        },
    },

    mounted() {

        this.user = this.getUser()
        var today = new Date()

        if (today.getDate() == 1) {
            today.setMonth(today.getMonth() - 1)
        }
        var firstDay=new Date(today.getFullYear(), today.getMonth(), 1)
        var lastDay = new Date(today.getFullYear(), today.getMonth()+1, 0)
        this.dateRange=[firstDay, lastDay]        
        this.fetchRecords()
    }     

}
</script>

<style>
tr:nth-child(even) {
  background-color: #f2f2f2;
}

.btn-label-text {
    padding-left: 5px;
}
</style>